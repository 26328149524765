import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import store from '../../store';
import {
  IDynamicAttributes,
  IInterscrollerAttributes,
  IJsonObject,
  IStickyAttributes,
  IAdUnitTestUrl,
  IConditionConfig,
  IVideoAttributes,
  MediaType,
  IGamAdUnitEntity,
  ZoneTargeting,
  ICreateOrUpdateZoneCategory,
  IAfsAttributes,
  IAdUnitEntity,
  IGamAdUnit,
} from '@publift-libs/fusetag-types-admin';
import { IUpsertAdUnit, IAdSize } from '@publift-libs/shared';
import { AxiosError } from 'axios';

export interface AdUnitApiResponse {
  items: AdUnit[];
}

export interface ISizeMap {
  xl?: string[];
  l?: string[];
  m: string[];
  s: string[];
  xs: string[];
}

export interface IAdUnitAttributes {
  refresh: boolean | number;
  adblock: boolean | number;
  headerbidding: boolean;
  interstitial?: boolean;
  sticky?: boolean;
  interscroller?: boolean;
  miniscroller?: boolean;
  dynamic?: boolean;
  scrollToStick?: boolean;
  afs?: boolean;
}

export interface ICsvContent {
  id: string;
  name?: string;
  account: string;
  ['sizeMap.xs']: string[];
  ['sizeMap.s']: string[];
  ['sizeMap.m']: string[];
  ['sizeMap.l']?: string[];
  ['sizeMap.xl']?: string[];
  // size: string;
  code: string;
  adblock?: string | boolean;
  refresh?: string | boolean;
  headerbidding?: string | boolean;
  interstitial?: string | boolean;
  sticky?: string | boolean;
  interscroller?: string | boolean;
  dynamic?: string | boolean;
  miniscroller?: string | boolean;
  scrollToStick?: string | boolean;
  afs?: string | boolean;
  activationType: string;
  activationCustom?: string;
  adUnitCode?: string;
  gamPath?: string;
  archived: boolean;
}

export interface IAdUnitExistingMap {
  adUnitId: string;
  includeChildren?: boolean;
  sizeMap?: Record<keyof ISizeMap, string[]>;
  attributes?: IAdUnitAttributes;
}

interface IDynamicObject {
  [key: string]: unknown;
}

export interface ICustomAttr {
  adUnitId: string;
  id: string;
  network: string;
  data: {
    params?: [];
  };
  automated: boolean;
  prebid_params: {
    bidder: string;
    params: IDynamicObject;
  };
}
export interface IAdmAdUnitParent {
  id: string;
  name: string;
  adUnitCode: string;
}

export interface IAdmAdUnitSize {
  size: {
    width: number;
    height: number;
    isAspectRatio?: boolean;
  };
  fullDisplayString?: string;
}

export interface AdManagerDateTime {
  date: AdManagerDate;
  hour: number;
  minute: number;
  second: number;
  timeZoneId: string;
}

export interface AdManagerDate {
  year: number;
  month: number;
  day: number;
}

export interface Rval<T> {
  rval: T;
}

export interface Page<T> {
  totalResultSetSize: number;
  startIndex: number;
  results: T[];
}

export interface IParentPath {
  id: string;
  name: string;
  adUnitCode: string;
}

export interface IAdmUnit {
  id: string;
  parentId: IParentPath;
  hasChildren: boolean;
  parentPath: IAdmAdUnitParent[];
  name: string;
  description: string;
  targetWindow: 'TOP' | 'BLANK';
  status: 'ACTIVE' | 'INACTIVE' | 'ARCHIVED';
  adUnitCode: string;
  adUnitSizes: IAdmAdUnitSize[];
  isInterstitial: boolean;
  isNative: boolean;
  isFluid: boolean;
  explicitlyTargeted: boolean;
  lastModifiedDateTime: AdManagerDateTime;
}

export interface AdUnit {
  id: string;
  code: string;
  parentId?: string;
  name?: string;
  adUnitId: string;
  accountCode: string;
  size?: string;
  isFluid: boolean;
  networkCode: string;
  canBeServed: boolean;
  platforms: null | number;
  status: string;
  formattedSizeMap: Partial<ISizeMap>;
  hasChildren?: boolean;
  archived: boolean;
  attributes?: Partial<IAdUnitAttributes>;
  customLazy?: {
    [breakpoint: string]: false | number;
  };
  stickyAttributes?: IStickyAttributes;
  interscrollerAttributes?: IInterscrollerAttributes;
  dynamicAttributes?: IDynamicAttributes;
  afsAttributes?: IAfsAttributes;
  insertCondition?: IJsonObject;
  sizeMap?: ISizeMap;
  sizeArray?: number[];
  custom: ICustomAttr[];
  network: {
    [network: string]: unknown;
  };
  divName?: string;
  testUrls?: IAdUnitTestUrl[];
  targeting?: ZoneTargeting;
  activationType: string;
  activationCustom?: IConditionConfig;
  videoAttributes?: IVideoAttributes;
  mediaTypes?: MediaType[];
  mediaTypesObject: {
    native: boolean;
    video: boolean;
  };
  gamAdUnit?: IGamAdUnitEntity;
  gamPath?: string;
  zoneCategories?: ICreateOrUpdateZoneCategory[];
}
export interface IAccount {
  name: string;
  code: string;
  admNetworkId: string;
  allowGamAdUnitCodeReuse?: boolean;
}

export interface AdUnitState {
  adunits: AdUnit[];
  editedIds: string[];
  filteredAdunits: AdUnit[];
  editDisable: boolean;
  listOfBidders: string[];
  fabMenu: boolean;
  adUnitsCache: AdUnit[];
  errorsPresent: boolean;
  mapAdmAdunits: boolean;
  activeAdunitAccount: IAccount;
  isCloning: boolean;
  cloneResult?: any;
  cloneError: string;
}

export interface ICloneAdUnit {
  adUnitId: string;
  networks: string[];
  adUnits: IUpsertAdUnit[];
}

export function getDownloadableNetworks(adunit: AdUnit, allowGamAdUnitCodeReuse: boolean): string[] {
  return adunit.custom
    .filter((network) => (!allowGamAdUnitCodeReuse ? !network.automated : true))
    .map((networkDetails) => {
      const network = store.getters['networks/getNetworkByCode'](networkDetails.network);
      return network.name;
    });
}

export const adunits: Module<AdUnitState, RootState> = {
  namespaced: true,

  state: {
    adunits: [],
    editedIds: [],
    filteredAdunits: [],
    editDisable: true,
    listOfBidders: [],
    fabMenu: false,
    adUnitsCache: [],
    errorsPresent: false,
    mapAdmAdunits: false,
    activeAdunitAccount: {
      name: '',
      code: '',
      admNetworkId: '',
    },
    isCloning: false,
    cloneResult: 55,
    cloneError: '',
  },

  getters: {
    adunits: (state) => state.adunits,
    editedIds: (state) => state.editedIds,
    filteredAdunits: (state) => state.filteredAdunits,
    editDisable: (state) => state.editDisable,
    listOfBidders: (state) => state.listOfBidders,
    fabMenu: (state) => state.fabMenu,
    adUnitsCache: (state) => state.adUnitsCache,
    errorsPresent: (state) => state.errorsPresent,
    mapAdmAdunits: (state) => state.mapAdmAdunits,
    activeAdunitAccount: (state) => state.activeAdunitAccount,
    isCloning: (state) => state.isCloning,
    cloneResult: (state) => state.cloneResult,
    cloneError: (state) => state.cloneError,
  },

  mutations: {
    setAdunits: (state, payload: AdUnit[]) => {
      // eslint-disable-next-line no-param-reassign
      state.adunits = payload;
    },
    addEditedId: (state, payload: string) => {
      state.editedIds.unshift(payload);
    },
    removeEditedId: (state, payload: string) => {
      state.editedIds.splice(state.editedIds.indexOf(payload), 1);
    },
    setEditedIds: (state, payload: string[]) => {
      // eslint-disable-next-line no-param-reassign
      state.editedIds = payload;
    },
    setFilteredAdunits: (state, payload: AdUnit[]) => {
      // eslint-disable-next-line no-param-reassign
      state.filteredAdunits = payload.filter((adunit) => !adunit.hasChildren);
    },
    /**
     * Enable or Disable the edit mode.
     */
    setEditDisable: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.editDisable = payload;
    },
    setListOfBidders: (state, payload: string[]) => {
      // eslint-disable-next-line no-param-reassign
      state.listOfBidders = payload;
    },
    toggleFabMenu: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.fabMenu = payload;
    },
    setAdUnitsCache: (state, payload: AdUnit[]) => {
      // eslint-disable-next-line no-param-reassign
      state.adUnitsCache = payload;
    },
    setErrorsPresent: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.errorsPresent = payload;
    },
    setMapAdmAdunits: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.mapAdmAdunits = payload;
    },
    setActiveAdunitAccount: (state, payload: IAccount) => {
      // eslint-disable-next-line no-param-reassign
      state.activeAdunitAccount = payload;
    },
    // Clone api call
    setIsCloning: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.isCloning = payload;
    },
    setCloneError: (state, payload: string) => {
      // eslint-disable-next-line no-param-reassign
      state.cloneError = payload;
    },
    setCloneResult: (state, payload?: any) => {
      // eslint-disable-next-line no-param-reassign
      state.cloneResult = payload;
    },
  },

  actions: {
    async cloneAdUnit(
      { dispatch },
      { accountCode, adUnits, networks, adUnitId }: ICloneAdUnit & { accountCode: string }
    ) {
      const tenantId = store.getters['tenants/tenantId'];

      dispatch('setCloneStarted');
      try {
        const { data } = await publiftApi.post(`/webapi/tenants/${tenantId}/accounts/${accountCode}/zones/clone`, {
          adUnits,
          adUnitId,
          networks,
        });

        dispatch('setCloneSuccess', data);
      } catch (e) {
        const reqError: AxiosError = e as AxiosError;
        const responseData = reqError.response?.data as { message?: string };
        dispatch('setCloneError', responseData.message || reqError.message || 'Clone AdUnit failed');
      }
    },

    setCloneStarted({ commit }) {
      commit('setIsCloning', true);
      commit('setCloneError', undefined);
    },
    setCloneSuccess({ commit }, payload: any) {
      commit('setCloneResult', payload);
      commit('setIsCloning', false);
      commit('setCloneError', undefined);
    },
    setCloneError({ commit }, payload: string) {
      commit('setIsCloning', false);
      commit('setCloneResult', undefined);
      commit('setCloneError', payload);
    },
    resetClone({ commit }) {
      commit('setCloneError', undefined);
      commit('setCloneResult', undefined);
      commit('setIsCloning', false);
    },

    addEditedId: ({ commit }, payload: string) => {
      commit('addEditedId', payload);
    },
    removeEditedId: ({ commit }, payload: string) => {
      commit('removeEditedId', payload);
    },
    setEditDisable: ({ commit }, payload: boolean) => {
      commit('setEditDisable', payload);
    },
    setEditedIds: ({ commit }, payload: string[]) => {
      commit('setEditedIds', payload);
    },
    setFilteredAdunits: ({ commit }, payload: AdUnit[]) => {
      commit('setFilteredAdunits', payload);
    },
    setListOfBidders: ({ commit }, payload: string[]) => {
      commit('setListOfBidders', payload);
    },
    setFabMenu: ({ commit }, payload: boolean) => {
      commit('toggleFabMenu', payload);
    },
    setAdUnitsCache: ({ commit }, payload: AdUnit[]) => {
      commit('setAdUnitsCache', payload);
    },
    setErrorsPresent: ({ commit }, payload: boolean) => {
      commit('setErrorsPresent', payload);
    },
    setMapAdmAdunits: ({ commit }, payload: boolean) => {
      commit('setMapAdmAdunits', payload);
    },
    setActiveAdunitAccount: ({ commit }, payload: IAccount) => {
      commit('setActiveAdunitAccount', payload);
    },
    getAdunitsInCSVFormat: (
      context,
      payload: {
        path: string;
        bidder: string;
        ids: string[];
        accountCode?: string;
      }
    ) => {
      const { path, bidder, ids, accountCode } = payload;
      const tenantId = store.getters['tenants/tenantId'];

      return publiftApi.post(`/webapi/tenants/${tenantId}/accounts/${accountCode}${path}`, {
        bidder,
        ids,
      });
    },
  },
};

// TODO, MOVE TO VUEX STYLED FLOW

export async function getGamAdUnits(accountCode: string, parentId?: string): Promise<IGamAdUnit[]> {
  const url = `/webapi/tenants/${store.getters['tenants/tenantId']}/accounts/${accountCode}/zones/adm/units`;
  const { data } = await publiftApi.get<IGamAdUnit[]>(url, {
    params: parentId ? { parentId } : undefined,
  });
  return data;
}

export async function mapGamAdUnitsAndCreateZones(
  accountCode: string,
  gamAdUnitIds: string[]
): Promise<IAdUnitEntity[]> {
  const url = `/webapi/tenants/${store.getters['tenants/tenantId']}/accounts/${accountCode}/zones/adm/map`;
  const { data } = await publiftApi.post<IAdUnitEntity[]>(url, { gamAdUnitIds });
  return data;
}

export async function getValidSizes(): Promise<string[]> {
  const tenantId = store.getters['tenants/tenantId'];
  const { data } = await publiftApi.get<IAdSize[]>(`/webapi/tenants/${tenantId}/ad-sizes`);

  return data.map(({ name }) => name);
}
