import { NavigationGuard, RouteConfig } from 'vue-router';

export type PrivateRoute = RouteConfig & {
  meta: {
    sidebar?: boolean;
    sidebarCategory?: string;
    permission?: string;
    icon?: string;
    displayName?: string;
    isExperimentFeature?: boolean;
  };
  children?: PrivateRoute[];
};

export const adUnitChildRoutes: PrivateRoute[] = [
  {
    path: 'inventoryV3Redirect',
    name: 'fuseInventoryV3Redirect',
    meta: {
      displayName: 'Inventory',
    },
    beforeEnter: () => {
      window.location.href = '/v3/ad_units_inventory';
    },
  },
  {
    path: 'adm/mapunits',
    name: 'AdmMapUnits',
    meta: {
      displayName: 'Import GAM Ad Units',
    },
    // beforeEnter: () => {
    //   window.location.href = '/v3/adm-mapunits';
    // },
    component: () => import(/* webpackChunkName: "adunit" */ '../views/adunits/ImportAdmAdUnits.vue'),
  },
];

export const manualReportingRoute = () => {
  const route: PrivateRoute = {
    path: '/manual-reporting',
    redirect: '/manual-reporting/manage-data',
    name: 'manualReporting',
    meta: {
      sidebar: true,
      icon: 'mdi-file-export',
      displayName: 'Manual Reporting',
    },
    component: () => import(/* webpackChunkName: "root" */ '../components/RouterParent.vue'),
    children: [
      {
        path: 'manage-data',
        name: 'manageReportingData',
        meta: {
          displayName: 'Manage Data',
        },
        beforeEnter: () => {
          window.location.href = '/v3/manual-reporting';
        },
      },
      {
        path: 'account-identifiers',
        name: 'accountIdentifiers',
        meta: {
          displayName: 'Account Identifiers',
        },
        beforeEnter: () => {
          window.location.href = '/v3/account-identifiers';
        },
      },
    ],
  };

  return route;
};

export const ga4Route = () => {
  const route: PrivateRoute = {
    path: '/google-analytics',
    name: 'googleAnalytics',
    meta: {
      sidebar: true,
      icon: 'mdi-google-analytics',
      displayName: 'Google Analytics (GA4)',
      v3Url: '/v3/google-analytics_properties',
    },
    children: [
      {
        path: 'properties',
        name: 'ga4Properties',
        meta: {
          displayName: 'Properties',
          v3Url: '/v3/google-analytics_properties',
        },
      },
      {
        path: 'account-domains',
        name: 'ga4AccountDomains',
        meta: {
          displayName: 'Account Domains',
          v3Url: '/v3/google-analytics_account-domains',
        },
      },
    ],
  };

  return route;
};

export const makeAccountChildRoutes = (): PrivateRoute[] => [
  {
    path: 'manage',
    name: 'accountManagement',
    meta: {
      displayName: 'Account Management',
    },
    beforeEnter: () => {
      window.location.href = '/v3/account-management';
    },
  },
  {
    path: 'network-configs',
    name: 'networkConfigs',
    meta: {
      displayName: 'Network Configuration',
      sidebar: true,
    },
    beforeEnter: () => {
      window.location.href = '/v3/network-configs';
    },
  },
];

export const v3Redirects: NavigationGuard = (to, _, next) => {
  if (to.meta?.v3Url) {
    window.location.href = to.meta.v3Url;
    return false;
  }
  next();
};

export const makeRoutes = (): PrivateRoute[] => {
  const routes: PrivateRoute[] = [
    {
      path: '/login',
      name: 'login',
      meta: {},
      component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Tiles.vue'),
      meta: {
        displayName: 'Home',
        permission: 'ADMIN_PANEL',
        icon: 'mdi-home',
      },
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        displayName: 'Users',
        sidebar: true,
        icon: 'mdi-account',
      },
      // component: UserManagement,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      beforeEnter: () => {
        window.location.href = '/v3/users';
      },
    },
    {
      path: '/accounts',
      name: 'accounts',
      redirect: '/accounts/manage',
      meta: {
        displayName: 'Accounts',
        sidebar: true,
        icon: 'mdi-account-settings',
      },
      // component: AccountManagement,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "account" */ '../components/RouterParent.vue'),
      children: makeAccountChildRoutes(),
    },
    // {
    //   path: '/logs',
    //   name: 'logs',
    //   meta: {
    //     displayName: 'Logs',
    //     sidebar: true,
    //     icon: 'mdi-history',
    //   },
    //   // component: Logs,
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "Logs" */ '../views/Logs.vue'),
    // },
    {
      path: '/clients',
      name: 'clients',
      meta: {
        sidebar: true,
        displayName: 'Clients',
        icon: 'mdi-account-group',
      },
      beforeEnter: () => {
        window.location.href = '/v3/clients';
      },
    },
    {
      path: '/serviceaccounts',
      name: 'service_accounts',
      beforeEnter: () => {
        window.location.href = '/v3/serviceaccounts';
      },
      meta: {
        sidebar: true,
        icon: 'mdi-account-key',
        displayName: 'Service Accounts',
      },
    },
    {
      path: '/networks',
      name: 'networks',
      beforeEnter: () => {
        window.location.href = '/v3/networks';
      },
      meta: {
        sidebar: true,
        icon: 'mdi-access-point-network',
        displayName: 'Networks',
      },
    },
    {
      path: '/ad_units',
      name: 'adunits',
      redirect: '/ad_units/inventoryV3Redirect',
      component: () => import(/* webpackChunkName: "adunit" */ '../components/RouterParent.vue'),
      meta: {
        displayName: 'Zones',
        sidebar: true,
        icon: 'mdi-television-guide',
      },
      children: adUnitChildRoutes,
    },
    {
      path: '/tags',
      name: 'tags',
      beforeEnter: () => {
        window.location.href = '/v3/tags';
      },
      meta: {
        displayName: 'Tags',
        sidebar: true,
        icon: 'mdi-cloud-tags',
      },
    },
    {
      path: '/ad_size',
      name: 'ad size',
      beforeEnter: () => {
        window.location.href = '/v3/ad_size';
      },
      meta: {
        sidebar: true,
        icon: 'mdi-image-size-select-large',
        displayName: 'Ad Size',
      },
    },
    {
      path: '/rev_share',
      name: 'Rev share',
      beforeEnter: () => {
        window.location.href = '/v3/rev_share';
      },
      meta: {
        sidebar: true,
        icon: 'mdi-currency-usd',
        displayName: 'Rev share',
      },
    },
    manualReportingRoute(),
    {
      path: '/fuse_experiments',
      name: 'Fuse Experiments',
      beforeEnter: () => {
        window.location.href = '/v3/fuse_experiments';
      },
      meta: {
        sidebar: true,
        icon: 'mdi-flask',
        displayName: 'Fuse Experiments',
        isExperimentFeature: true,
      },
    },
    {
      path: '/tag_history',
      name: 'tag history',
      beforeEnter: () => {
        window.location.href = '/v3/tag_history';
      },
      meta: {
        displayName: 'Tag History',
        sidebar: true,
        icon: 'mdi-history',
      },
    },
    {
      path: '/error',
      name: 'error',
      meta: {},
      component: () => import(/* webpackChunkName: "login" */ '../views/Error.vue'),
    },
    ga4Route(),
    // {
    //   path: '/filter',
    //   name: 'filter',
    //   component: Filtering,
    // },
  ];

  return routes;
};
